import React, { useState, useRef } from "react";
import axios from "axios";

const API_KEY = "AIzaSyBxQuJNekACxB3jYYu8kMb3qBooRJqe3OI";

const App = () => {
  const [recording, setRecording] = useState(false);
  const [audioArrayBuffer, setAudioArrayBuffer] = useState(null);
  const audioRef = useRef(null);
  let mediaRecorder;

  const handleClick = async () => {
    if (recording && mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
  
      mediaRecorder = new MediaRecorder(stream);
      let audioChunks = [];
  
      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });
  
      mediaRecorder.addEventListener("stop", () => {
        const audioBlob = new Blob(audioChunks);
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current.src = audioUrl;
        audioRef.current.load();
  
        const reader = new FileReader();
        reader.readAsArrayBuffer(audioBlob);
        reader.onloadend = () => {
          setAudioArrayBuffer(reader.result);
        };
      });
  
      mediaRecorder.start();
      setRecording(true);
    }
  };
  

  const handleSubmit = async () => {
    if (!audioArrayBuffer) return;

    const response = await axios.post(
      "https://speech.googleapis.com/v1/speech:recognize?key=" + API_KEY,
      {
        config: {
          encoding: "LINEAR16",
          sampleRateHertz: 16000,
          languageCode: "en-US",
        },
        audio: {
          content: Array.from(new Uint8Array(audioArrayBuffer))
            .map((byte) => String.fromCharCode(byte))
            .join(""),
        },
      }
    );
    console.log(response.data);
  };

  return (
    <div className="mobile_body">
      <div className="ai_container">
        <div className="ai_face" />
        <p className="ai_name">Neinth</p>
        <div className="ai_controls">
          <span className="micBtn" onClick={handleClick} />
          <span className="playBtn" onClick={() => audioRef.current.play()} />
          <span className="modeBtn" onClick={handleSubmit} />
        </div>
      </div>
      <audio ref={audioRef} />
    </div>
  );
};
export default App;
